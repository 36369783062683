import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar";
import ccBy from "../img/by.svg";

const TemplateWrapper: React.SFC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
              siteUrl,
            }
          }
        }
    `}
    render={data => (
      <React.Fragment>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        </Helmet>
        <Navbar />
        {children}
        <footer className="footer" lang="en">
          <div className="content has-text-centered is-small is-italic">
            <a rel="license" href="https://creativecommons.org/licenses/by/4.0/">
              <img alt="Creative Commons License" src={ccBy} width="90" />
            </a>
            <br />Unless specified otherwise, my work is licensed under a <a rel="license" href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a>.
          </div>
        </footer>
      </React.Fragment>
    )}
  />
);

export default TemplateWrapper;
